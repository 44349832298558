<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Quản lý Segment user</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Nhập số điện thoại (tối đa 10 số điện thoại)">
              <b-form-input
                v-model="segment.phone"
                placeholder="Số điện thoại cách nhau bởi dấu ,"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Nhập số điện thoại">
              <b-form-file v-model="file" placeholder="Chọn hoặc kéo file vào đây.(xls, xlsx)" drop-placeholder="Kéo file vào đây..."
              ></b-form-file>
            </b-form-group>
            <p>Download file mẫu <a href="https://cdn.9pay.vn/templates/import_users.xlsx" target="_blank">TẠI ĐÂY</a></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-info" @click="listAll()"
              >Tìm kiếm</b-button
            >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="addUserSegment()"
              >Thêm mới</b-button
            >
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
          style="text-align: center"
        >
          <template #cell(options)="data">
            <span
              type="button"
              class="btn btn-outline-danger"
              @click="deleteUserSegment(data.item.user_id)"
              >Xóa</span
            >
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.total"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const CmsRepository = RepositoryFactory.get("cms");
import Common from "@/core/mixins/common";
export default {
  components: {},
  mixins: [Common],
  data() {
    return {
      file: null,
      extensions: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      segment: {
        phone: null,
      },
      items: [],
      fields: [
        { id: "Id" },
        { user_id: "User Id" },
        { phone: "Phone" },
        { options: "Tùy chọn" },
      ],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        lastPage: 1,
        total: 1,
        skip: 0,
      },
    };
  },
  methods: {
    clearInput() {
      this.segment.phone = null;
    },
    addUserSegment() {
      if (this.segment.phone == null && this.file == null) {
        this.notifyAlert("warn", "Số điện thoại và file import không được bỏ trống");
        return;
      }

      if (this.file && this.extensions.indexOf(this.file.type) === -1) {
        this.notifyAlert(
            "warn",
            "File không đúng định dạng, vui lòng kiểm tra lại"
        );
        return false;
      }

      if (this.file && this.file.size <= 0) {
        this.notifyAlert("warn", "File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      if (
        this.$route.params.id === "undefined" ||
        this.$route.params.id === ""
      ) {
        this.notifyAlert("warn", "segment_id không được bỏ trống");
        return;
      }

      let params = new FormData();
      params.append("file", this.file);
      params.append("phone", this.segment.phone);
      params.append("segment_id", this.$route.params.id);

      // let params = {
      //   phone: this.segment.phone,
      //   segment_id: this.$route.params.id,
      // };
      this.$bus.$emit("show-loading", true);
      CmsRepository.addUserSegement(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
          } else {
            this.clearInput();
            this.listAll();
            this.notifyAlert("success", "Thêm user thành công");
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    deleteUserSegment(user_id) {
      if (!confirm("Bạn chắc chắn muốn xóa chứ?")) {
        return;
      }
      let params = {
        segment_id: this.$route.params.id,
        user_id: user_id,
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.deleteUserSegement(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
          } else {
            this.clearInput();
            this.notifyAlert("success", "Xóa user thành công");
            this.listAll();
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    listAll() {
      if (
        this.$route.params.id === "undefined" ||
        this.$route.params.id === ""
      ) {
        this.notifyAlert("warn", "segment_id không được bỏ trống");
        return;
      }
      let params = {
        segment_id: this.$route.params.id,
      };
      if (this.segment.phone != null || this.segment.phone === "") {
        params.phone = this.segment.phone;
      }
      if (this.$route.query.page) {
        params.page = this.$route.query.page;
      } else {
        params.page = 1;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.listUserSegement(params)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.last_page;
            this.paginate.page = response.data.data.current_page;
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("success", "Lấy danh sách thành công");
          }
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          this.notifyAlert("warn", "Có lỗi xảy ra");
        });
    },
  },
  watch: {
    currentPage() {
      this.listAll();
    },
  },
  created() {
    this.listAll();
  },
};
</script>
